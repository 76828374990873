import {
    IconBrandGithub,
    IconBrandInstagram,
    IconBrandLinkedin,
    IconBrandMedium,
    IconCamera,
    IconWriting
} from "@tabler/icons-react";
import {openInNewTab} from "../../lib/routing";


export const ExternalLinks = () => {

    return (
        <div className={"flex flex-row mt-10"}>
            <div className={"cursor-pointer mr-5"}
                 onClick={() => openInNewTab("https://www.instagram.com/aseem.savio/")}>
                <IconBrandInstagram className="h-full w-full text-white dark:text-neutral-300"/>
            </div>

            <div className={"cursor-pointer mr-5"} onClick={() => openInNewTab("https://photography.aseemsavio.com")}>
                <IconCamera className="h-full w-full text-white dark:text-neutral-300"/>
            </div>

            <div className={"cursor-pointer mr-5"} onClick={() => openInNewTab("https://blog.aseemsavio.com")}>
                <IconWriting className="h-full w-full text-white dark:text-neutral-300"/>
            </div>

            <div className={"cursor-pointer mr-5"} onClick={() => openInNewTab("https://medium.com/@aseemsavio")}>
                <IconBrandMedium className="h-full w-full text-white dark:text-neutral-300"/>
            </div>

            <div className={"cursor-pointer mr-5"}
                 onClick={() => openInNewTab("https://www.linkedin.com/in/aseemsavio/")}>
                <IconBrandLinkedin className="h-full w-full text-white dark:text-neutral-300"/>
            </div>

            <div className={"cursor-pointer"} onClick={() => openInNewTab("https://github.com/aseemsavio")}>
                <IconBrandGithub className="h-full w-full text-white dark:text-neutral-300"/>
            </div>
        </div>
    )
}
